import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { PermissionService, PipelinePermission } from '@selfai-platform/shared';
import { KeCurrentUserService } from '../../current-user';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsPermissionService {
  constructor(
    private readonly permissionService: PermissionService,
    private readonly keCurrentUserService: KeCurrentUserService,
  ) {}

  public hasCurrentPermission(permission: PipelinePermission): boolean {
    return this.getUserFunctionsPermissions().includes(permission);
  }

  public canCreateUserFunction(): boolean {
    return this.hasCurrentPermission(PipelinePermission.UserFunctionsCreate);
  }

  public canUpdateUserFunction(userFunctionOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.UserFunctionsUpdateAny)) {
      return of(true);
    }

    return this.equalCurrentUser(userFunctionOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.UserFunctionsUpdateOwn)),
    );
  }

  public canDeleteUserFunction(userFunctionOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.UserFunctionsDeleteAny)) {
      return of(true);
    }

    return this.equalCurrentUser(userFunctionOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.UserFunctionsDeleteOwn)),
    );
  }

  private getUserFunctionsPermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('functions'));
  }

  private equalCurrentUser(userId: string): Observable<boolean> {
    return this.keCurrentUserService.getById().pipe(map((data) => data.externalId === userId));
  }
}
