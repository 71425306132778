import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  CubeWorkflowData,
  GraphNode,
  WriteDataFrameTransitFormGroup,
  WriteDataFrameTransitParameters,
  WriteDataFrameTransitRawParameters,
  WriteDataFrameTransitSaveMode,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { DataSourceSelectorComponent } from '../../data-source-selector';
import { DialogHeaderComponentModule } from '../../dialog-header';

@Component({
  selector: 'selfai-platform-write-data-frame',
  templateUrl: './write-data-frame.component.html',
  styleUrl: './write-data-frame.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponentModule,
    DataSourceSelectorComponent,
    CheckboxModule,
    ReactiveFormsModule,
    TranslateModule,
    MessageModule,
    DialogHeaderComponentModule,
    DropdownModule,
  ],
})
export class WriteDataFrameComponent implements OnInit {
  get node(): GraphNode<WriteDataFrameTransitRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): WriteDataFrameTransitParameters {
    return this.normalizeRawWriteDataFrameTransitParameters(this.node.parameters?.serialize());
  }

  form = new FormGroup<WriteDataFrameTransitFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    overwrite: new FormControl(null),
    saveMode: new FormControl(null),
  });

  saveModeOptions: WriteDataFrameTransitSaveMode[] = Object.values(WriteDataFrameTransitSaveMode);

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<WriteDataFrameTransitRawParameters>>,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as WriteDataFrameTransitParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeFormValuesToApiModel(params: WriteDataFrameTransitParameters): WriteDataFrameTransitRawParameters {
    return {
      'data source': params.dataSourceId,
      overwrite: params.overwrite,
      'Save mode (only for JDBC)': params.saveMode ? { [params.saveMode]: {} } : null,
    };
  }

  private normalizeRawWriteDataFrameTransitParameters(
    rawParameters: WriteDataFrameTransitRawParameters,
  ): WriteDataFrameTransitParameters {
    return {
      dataSourceId: rawParameters['data source'],
      overwrite: rawParameters.overwrite,
      saveMode: rawParameters['Save mode (only for JDBC)']
        ? (Object.keys(rawParameters['Save mode (only for JDBC)'])[0] as WriteDataFrameTransitSaveMode)
        : null,
    };
  }
}
