import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  CodeEditorConfig,
  CubeWorkflowData,
  EnrichmentToolFormGroup,
  EnrichmentToolParameters,
  EnrichmentToolRawParameters,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MessageModule } from 'primeng/message';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { DataSourceSelectorComponent } from '../../data-source-selector/data-source-selector.component';
import { DialogHeaderComponentModule } from '../../dialog-header';

@Component({
  selector: 'selfai-platform-enrichment-tool',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormFieldComponentModule,
    DialogHeaderComponentModule,
    DataSourceSelectorComponent,
    TranslateModule,
    MessageModule,
    MonacoEditorModule,
  ],
  templateUrl: './enrichment-tool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrichmentToolComponent {
  editorOptions: CodeEditorConfig = {
    theme: 'vs',
    language: 'sql',
    automaticLayout: true,
    minimap: { enabled: false },
    wordWrap: 'on',
  };

  get node(): GraphNode<EnrichmentToolRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): EnrichmentToolParameters {
    return this.normalizeRawParameters(this.node.parameters?.serialize());
  }

  form = new FormGroup<EnrichmentToolFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    sqlQuery: new FormControl(null),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<EnrichmentToolRawParameters>>,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
  }

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as EnrichmentToolParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeRawParameters(params: EnrichmentToolRawParameters): EnrichmentToolParameters {
    return {
      sqlQuery: params['Enrichment SQL query'],
      dataSourceId: params['Choose JDBC Datasource'],
    };
  }

  private normalizeFormValuesToApiModel(formValues: EnrichmentToolParameters): EnrichmentToolRawParameters {
    return {
      'Enrichment SQL query': formValues.sqlQuery,
      'Choose JDBC Datasource': formValues.dataSourceId,
    };
  }
}
