import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodeEditorConfig, CodeLanguage } from '@selfai-platform/pipeline-common';
import { registerFields, resetLanguage } from '../../languages';

@Component({
  selector: 'selfai-platform-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeEditorComponent),
      multi: true,
    },
  ],
})
export class CodeEditorComponent implements ControlValueAccessor, OnDestroy {
  @Input() canMaximized = true;

  @Input() set language(value: CodeLanguage) {
    this.editorOptions = { ...this.editorOptions, language: value };
  }

  @Input() set fields(value: string[]) {
    registerFields(value);
  }

  value = '';
  disabled = false;
  maximized = false;

  onChange!: (_: string) => void;

  editorOptions: CodeEditorConfig = {
    theme: 'vs',
    language: 'sql',
    automaticLayout: true,
    minimap: { enabled: false },
    wordWrap: 'on',
  };

  private onTouched!: () => void;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    resetLanguage();
  }

  maximazeEditor(): void {
    this.maximized = true;
  }

  toggleWordWrap(): void {
    const wordWrap = this.editorOptions.wordWrap === 'on' ? 'off' : 'on';
    this.editorOptions = { ...this.editorOptions, wordWrap };
  }

  writeValue(value: string): void {
    this.value = value;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
