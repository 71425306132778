import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of, take } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  KE_USER_FUNCTIONS_PATH,
  KE_ROOT_ROUTE,
  MenuItemsNormalizerService,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { UrlPageParamsService } from '@selfai-platform/shared';
import { IFunctionTag, UserFunctionsListItem, UserFunctionApi } from '../models';
import { USER_FUNCTIONS_TABLE_COLUMNS_CONFIG } from '../constants';
import { UserFunctionsListDomainService } from './user-functions-list-domain.service';

@Injectable()
export class UserFunctionsDataListViewService extends DataListViewComponentService<UserFunctionsListItem> {
  constructor(
    private readonly userFunctionsListDomainService: UserFunctionsListDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemService: SelectedItemsService<UserFunctionsListItem>
  ) {
    super(urlPageParamsService, selectedItemService);
  }

  override loadData(): Observable<{ items: UserFunctionsListItem[]; totalItems: number }> {
    return this.userFunctionsListDomainService.loadUserFunctionsList().pipe(
      map(({ userFunctions }) => ({
        items: userFunctions.map((item) => this.normalizeForView(item)),
        totalItems: userFunctions.length,
      })),
    );
  }

  override getData(): Observable<UserFunctionsListItem[]> {
    return this.userFunctionsListDomainService
      .getFilteredFunctionsList()
      .pipe(map((items) => (items ? items.map((item) => this.normalizeForView(item)) : [])));
  }

  override isLoading(): Observable<boolean> {
    return this.userFunctionsListDomainService.getUserFunctionsListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.userFunctionsListDomainService.getUserFunctionsListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.userFunctionsListDomainService.getUserFunctionsListError().pipe(map((error) => !!error));
  }

  public getColumns(): TableColumn<UserFunctionsListItem>[] {
    return USER_FUNCTIONS_TABLE_COLUMNS_CONFIG as TableColumn<UserFunctionsListItem>[];
  }

  public getGroupActions(): Observable<GroupAction[]> {
    const actions: GroupAction[] = [];

    actions.push({
      tooltipTranslate: 'user-functions-list.toolbar.actions.delete-selected',
      icon: 'pi pi-trash',
      buttonClass: 'p-button-danger',
      action: () => this.deleteSelected(),
    });

    return of(actions);
  }

  public filterData(tags: IFunctionTag[] = []): void {
    this.userFunctionsListDomainService.filterFunctionList(tags);
  }

  private deleteSelected(): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('user-functions-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((datasourceId) => {
              this.userFunctionsListDomainService.deleteUserFunction(datasourceId);
            });
          });
      },
    });
  }

  private normalizeForView(userFunction: UserFunctionApi): UserFunctionsListItem {
    return {
      ...userFunction,
      name: userFunction.name,
      tags: userFunction.tags?.map(t => (t as IFunctionTag).name),
      scopeType: userFunction.scopes?.map((scope) => scope.scopeType),
      routerLinkToItem: {
        routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH, 'edit', userFunction.id],
      },
    };
  }
}
