<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [columns]="columns"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
>
  <ng-container slot="toolbarActions">
    <p-multiSelect
      optionLabel="name"
      placeholder="Select tags"
      [(ngModel)]="selectedTags"
      [options]="tags"
      [showClear]="true"
      (onClear)="onClearTagsFilter()"
      (onChange)="onFilterByTags($event)"
    >
      <ng-template class="select-item" let-tag pTemplate="item">
        <div class="select-item flex align-items-center justify-content-between w-full">
          <div>{{ tag.name }}</div>
          <i class="delete-tag-button pi pi-trash hover" (click)="removeTag(tag); $event.stopPropagation()"></i>
        </div>
      </ng-template>
    </p-multiSelect>

    <p-button
      *ngIf="canCreate"
      class="p-button-success"
      severity="success"
      type="button"
      routerLink="./create"
      icon="pi pi-plus-circle"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.create' | translate }}"
    ></p-button>

    <p-button
      type="button"
      icon="pi pi-upload"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.import' | translate }}"
      (click)="importUserFunctions()"
    ></p-button>

    <p-button
      type="button"
      icon="pi pi-download"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.export' | translate }}"
      (click)="exportUserFunction()"
    ></p-button>
  </ng-container>
</selfai-platform-data-list-view>
